import Img from "gatsby-image"
import { Link } from "gatsby"
import React from "react"
import PostTags from "./post-tags"

export default function PostList({ posts }) {
  return (
    <ol style={{ listStyle: `none` }}>
      {posts.map(post => {
        const title = post.frontmatter.title || post.fields.slug
        const author = post.frontmatter.author
        const image = author.avatar

        return (
          <li key={post.fields.slug}>
            <article
              className="post-list-item"
              itemScope
              itemType="http://schema.org/Article"
            >
              <div className="imagePostContainer">
                <Img
                  fluid={post.frontmatter.featured.childImageSharp.fluid}
                  role="presentation"
                  alt=""
                />
              </div>
              <div className="summaryPostContainer">
                <header>
                  <PostTags tags={post.fields.tags} />
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
                <section className="author-info">
                  <Img
                    fixed={image.childImageSharp.fixed}
                    alt={author.name}
                    className="author-avatar"
                  />
                  <div
                    className="text-muted"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <small className="bold">{author.name}</small>
                    <small>{post.frontmatter.date}</small>
                  </div>
                </section>
              </div>
            </article>
            <hr />
          </li>
        )
      })}
    </ol>
  )
}
